<template>
  <footer>
    <p>
      <router-link to="/">
        <img src="@/assets/britek-white.svg" alt="Britek" />
      </router-link>
      desenvolvido por
      <a
        href="https://www.rodrigobrito.dev.br"
        title="rodrigobrito.dev.br"
        target="_blank"
        rel="norefer"
        >Rodrigo Brito</a
      >
    </p>
  </footer>
</template>

<script>
export default {
  name: "TheFooter",
};
</script>

<style scoped>
footer {
  background: #87f;
  padding: 25px 0;
  justify-content: center;
  font-size: 1rem;
  color: #fff;
  position: relative;
  top: 75px;
  display: flex;
  align-items: center;
}

p {
  display: flex;
  align-items: baseline;
}

img {
  width: 90px;
}

a {
  padding: 0 5px;
}
</style>
