<template>
  <div>
    <h1>Página não encontrada</h1>
    <router-link class="home btn" to="/">Voltar para Home</router-link>
  </div>
</template>
<script>
export default {
  name: "PaginaNaoEncontrada",
  created() {
    document.title = "Britek | Página não encontrada";
  },
};
</script>
<style scoped>
h1 {
  margin: 40px auto;
  text-align: center;
}

.home {
  display: block;
  text-align: center;
  width: 200px;
  margin: 0 auto;
}
</style>